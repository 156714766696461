<template>
  <div class="site-management" ref="SiteManagement">
    <div class="function-buttons" ref="functionButtons">
      <el-button
        type="primary"
        size="small"
        @click="onAddSite"
        v-if="$store.state.menu.nowMenuList.indexOf('新增') >= 0"
        >新增</el-button
      >
      <el-button
        type="primary"
        size="small"
        @click="onExport"
        v-if="$store.state.menu.nowMenuList.indexOf('模板下载') >= 0"
        >模板下载</el-button
      >
      <uploadFile
        @uploadSuccess="uploadSuccess"
        url="/base/device/import"
        class="import"
      ></uploadFile>
    </div>
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="72px"
      >
        <el-form-item label="线路：" prop="siteName">
          <el-input
            v-model="form.driverName"
            placeholder="请输入线路"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="onSearch"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" stripe :height="tableHeight">
      <el-table-column label="序号" type="index" width="60"></el-table-column>
      <el-table-column prop="physicalNo" label="站点名称"></el-table-column>
      <el-table-column prop="cardNo" label="线路编号"></el-table-column>
      <el-table-column prop="physicalNo" label="方向"></el-table-column>
      <el-table-column prop="physicalNo" label="线路名称"></el-table-column>
      <el-table-column prop="driverName" label="距离起点"></el-table-column>
      <el-table-column prop="driverName" label="标志"></el-table-column>
      <el-table-column prop="drivingLicense" label="经度"></el-table-column>
      <el-table-column prop="drivingLicense" label="纬度"></el-table-column>
      <el-table-column label="操作" width="140">
        <template slot-scope="scope">
          <el-button
            @click="onUpdateCard(scope.row)"
            type="text"
            size="small"
            sort="primary"
            v-if="$store.state.menu.nowMenuList.indexOf('编辑') >= 0"
            >编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            sort="danger"
            @click="onDeleteCard(scope.row)"
            v-if="$store.state.menu.nowMenuList.indexOf('删除') >= 0"
            >遗失</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @current-change="onCurrentChange"
        @size-change="onSizeChange"
      ></el-pagination>
    </div>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      @close="onDialogClose"
      width="30%"
    >
      <addSite
        ref="addSite"
        :item="addForm"
        @close="onDialogClose"
        @onLoading="onLoading"
      />
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="editLoading"
          @click="onSave"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { formatDict } from '@/common/utils/index'
import { getCardList, delSite, queryDictsByCodes } from '@/api/lib/api.js'
import uploadFile from '@/components/uploadFile/uploadFile.vue'
import addSite from './components/addSite'
export default {
  name: 'SiteManagement',
  components: {
    uploadFile,
    addSite
  },
  data() {
    var checkRangeNumber = (rule, value, callback) => {
      const regEn = /^[0-9]{0,20}$/
      if (value === '') {
        callback()
      } else {
        if (!regEn.test(value)) {
          callback(new Error('请输入不超过20位数字'))
        } else {
          callback()
        }
      }
    }
    return {
      tableHeight: 0,
      tableData: [],
      form: {
        siteName: null,
        currentPage: 1,
        pageSize: 10
      },
      addForm: {
        serialNo: '',
        deviceNo: '',
        sim: '',
        producerId: '',
        deviceType: '',
        deviceModel: ''
      },
      rules: {
        serialNo: [
          { required: true, message: '请输入IC卡自编号', trigger: 'blur' },
          { validator: checkRangeNumber, trigger: 'blur' }
        ],
        deviceNo: [
          { required: true, message: '请输入设备号', trigger: 'blur' },
          { validator: checkRangeNumber, trigger: 'blur' }
        ],
        sim: [
          { required: true, message: '请输入SIM卡号', trigger: 'blur' },
          { validator: checkRangeNumber, trigger: 'blur' }
        ],
        producerId: [
          { required: true, message: '请选择生产商', trigger: 'change' }
        ],
        deviceType: [
          { required: true, message: '请选择设备类型', trigger: 'change' }
        ],
        deviceModel: [
          { required: true, message: '请选择设备型号', trigger: 'change' }
        ]
      },
      total: 250,
      title: '',
      siteList: [],
      siteObj: null,
      dialogVisible: false,
      editLoading: false
    }
  },
  methods: {
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.SiteManagement.clientHeight
      const buttonsHeight = this.$refs.functionButtons.clientHeight
      const formHeight = this.$refs.form.clientHeight
      const paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        16
    },
    onSearch() {
      this.searchLoading = true
      this.form.currentPage = 1
      this.form.pageSize = 10
      this.getListByField()
    },
    getListByField() {
      getCardList(this.form).then((res) => {
        if (res.code === 1000) {
          this.tableData = res.data.list
          this.total = res.data.total
        } else {
          this.tableData = []
          this.total = 0
          this.$message.error(res.msg)
        }
      })
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.form.currentPage = page
      this.getListByField()
    },
    // 当前条数改变
    onSizeChange(size) {
      this.form.pageSize = size
      this.form.currentPage = 1
      this.getListByField()
    },
    // 下载模板
    onExport() {},
    // 新增车场
    onAddSite() {
      this.title = '新增车场'
      this.addForm = null
      this.dialogVisible = true
    },
    // 点击表格查看按钮
    onUpdateCard(row) {
      this.title = '修改车场'
      this.addForm = { ...row }
      this.dialogVisible = true
    },
    // 删除IC卡数据
    onDeleteCard(row) {
      const h = this.$createElement
      this.$msgbox({
        title: '消息',
        type: 'warning',
        message: h('p', null, [
          h('span', null, '您将删除IC卡 '),
          h('span', { style: 'color: #059370' }, row.name),
          h('span', null, '  ，是否删除？')
        ]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          deleteDevice(row.id).then((res) => {
            if (res.code === 1000) {
              this.$message.success('删除成功')
              this.onSearch()
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            showClose: true,
            message: '已取消删除'
          })
        })
    },

    // 关闭弹窗
    onDialogClose(flag) {
      this.dialogVisible = false
      if (flag) {
        if (this.title.indexOf('新增') > -1) {
          this.onSearch()
        } else {
          this.getListByField()
        }
      }
      this.editLoading = false
    },
    //  保存
    onSave() {
      this.$refs.addSite.onSave()
    },
    onLoading(flag) {
      this.editLoading = flag
    },
    // 关闭弹窗
    closeDialog(flag) {
      this.dialogVisible = false
      if (flag) {
        if (this.title.indexOf('新增') > -1) {
          this.onSearch()
        } else {
          this.getListByField()
        }
      }
    },
    // 文件上传成功
    uploadSuccess() {
      this.onSearch()
    },
    //获取字典值
    getDicts() {
      queryDictsByCodes({ parentCodes: 'SBCJ' }).then((res) => {
        if (res.code === 1000) {
          this.siteList = res.data.SBCJ
          this.siteObj = formatDict(this.siteList)
        }
      })
    }
  },
  created() {
    this.getDicts()
  },
  mounted() {
    this.$nextTick(() => {
      this.computeHeight()
      this.onSearch()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.site-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  @include themify() {
  }
}

.theme-project-resourse {
  .site-management {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
